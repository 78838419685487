
/* .bg_halfgrey{
    background: linear-gradient(to left, white 50%, #F7F7F7 50%);
} */

body{
    font-family: 'Roboto', sans-serif;


}

input:focus, textarea:focus, select:focus {
    outline:   none;
    box-shadow: 0 0 2pt 0.5pt cornflowerblue;
  }

.loader {
    border: 1px solid #f3f3f3;
    border-radius: 50%;
    border-top: 1px solid #3456d1;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 0.5s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }



/* image capture flash */

video{
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
@keyframes flash {
    0%,25%, 75% {
        opacity: 0;
     }
     50%, 100% {
       opacity: 1;
    }

 }

 .flash {
    animation-name: flash;
 }



 /* FADE IN */
.fade {


    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media (min-width: 1536px){
.container {
    max-width: 1650px;
}

}



