
.sh-video-area{

    width: 100%;
    position: absolute;
    background-color: rgb(255, 255, 255);
}

.video_operations{
    width: 100%;
    background: #00000054;
    /* padding: 10px 0px 10px 0px; */
    color: rgb(255, 255, 255);
}
.video_operations i{
   margin: 0px 4px 0px 4px;
}

.sh-videoplayer{
    display:none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}




.video_thumbnail{
    display:block;
    
}


.video-area:hover > .sh-videoplayer, .videoContainer:hover  .video_thumbnail {
    display:block;
  
    
 
}
.video_control_right{
    width :70%;
    display: flex;
    justify-content: flex-end;

 }

 .video_control_right .video_control{
    margin-left: 5px;
 }


 /***/

.video_control_left{
   width :30%;
   display: flex;
   justify-content: flex-start;
}

.video_control_left .video_control{
    margin-right: 5px;
 }





 .sh-video{
    width: 100%;
}





.progressbar{
    width: 100%;
    height: 8px;
    background-color: rgb(170, 170, 175);
}

.progress{
    height: 100%;
    width: 2%;
    border-radius: 0px 4px 4px 0px;
    background-color: #3253D1;
}



.vidinfo{display: flex;
    justify-content: space-between;
}

.sh-video-area{
    position: relative;
    height: 100%;
    padding: 20px;
}




.progress-area{
 cursor: pointer;
}

.fas{
    color: #ffffff;
    cursor: pointer;
    background-color: #3253D1;
}



.video_control{

    width: 50px;
cursor: pointer;
    color:   ghostwhite;
}

 

.disable-fas{
    color: #ffffff;
    cursor: pointer;
    background-color: #babbbb;
}


#canvas{
    width: 100%;
}